import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HashLoader } from "react-spinners"
const backend = require("../helpers/devsurvival.api.js")

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    title: "",
    description: "",
    demoLink: "",
    personalWeb: "",
    twitter: "",
    github: "",
    facebook: "",
    technologies: "",
  })
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState("")

  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  const createShowcase = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const showcase = await backend.createShowcase({
        ...formState,
        technologies: technologies.replace(/ /g, "").split(","),
      })
      console.log("new showcase", showcase)
      setNotification("your showdev has been submitted!")
    } catch (err) {
      setNotification("server error. failed to process your request")
    }

    setLoading(false)
  }
  const {
    name,
    email,
    title,
    description,
    demoLink,
    personalWeb,
    twitter,
    github,
    facebook,
    technologies,
  } = formState

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Create Showcase" />
      <h4>Submit Your ShowDev</h4>
      {loading ? (
        <HashLoader />
      ) : (
        <form className="flex-column-start" onSubmit={createShowcase}>
          {notification && <div>{notification}</div>}
          <input
            type="text"
            placeholder="your name*"
            value={name}
            name="name"
            required
            onChange={onChange}
          />
          <input
            type="email"
            placeholder="your email will not be shared with others*"
            value={email}
            name="email"
            required
            onChange={onChange}
          />

          <input
            type="text"
            placeholder="project title*"
            value={title}
            name="title"
            required
            onChange={onChange}
          />

          <input
            type="text"
            placeholder="description*"
            value={description}
            name="description"
            required
            onChange={onChange}
          />

          <input
            type="text"
            placeholder="link to demo"
            value={demoLink}
            name="demoLink"
            onChange={onChange}
          />
          <input
            type="text"
            placeholder="comma separated list of technologies*"
            value={technologies}
            name="technologies"
            required
            onChange={onChange}
          />

          <input
            type="text"
            placeholder="personal website"
            value={personalWeb}
            name="personalWeb"
            onChange={onChange}
          />

          <input
            type="text"
            placeholder="twitter link"
            value={twitter}
            name="twitter"
            onChange={onChange}
          />

          <input
            type="text"
            placeholder="github link"
            value={github}
            name="github"
            onChange={onChange}
          />
          <input
            type="text"
            placeholder="facebook link"
            value={facebook}
            name="facebook"
            onChange={onChange}
          />

          <button type="submit">Submit</button>
        </form>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
